import { string, date, object, boolean, array, mixed } from "yup";
const ExperienceSchema = object({
  experience_id: string().nullable(),
  model_id: string().nullable(),
  year: string().nullable(),
  country: string().nullable(),
  product: string().nullable(),
  media: string().nullable(),
  details: string().nullable(),
});

const MeasurementSchema = object({
  height: string().nullable(),
  weight: string().nullable(),
  chest_bust_cup: string().nullable(),
  collar: string().nullable(),
  around_armpit: string().nullable(),
  around_arm_to_wrist1: string().nullable(),
  around_arm_to_wrist2: string().nullable(),
  around_arm_to_wrist3: string().nullable(),
  arm_length1: string().nullable(),
  arm_length2: string().nullable(),
  around_thick_to_ankle: string().nullable(),
  trousers_length: string().nullable(),
  chest_height: string().nullable(),
  chest_width: string().nullable(),
  waist: string().nullable(),
  hips: string().nullable(),
  shoulder: string().nullable(),
  front_shoulder: string().nullable(),
  front_length: string().nullable(),
  back_shoulder: string().nullable(),
  back_length: string().nullable(),
  crotch: string().nullable(),
  bra_size: string().nullable(),
  suit_dress: string().nullable(),
  shoes_size: string().nullable(),
  eye_colour: string().nullable(),
  hair_colour: string().nullable(),
});

export const createModelSchema = object({
  model_id: string().nullable(),
  first_name: string().required("First name cannot be empty."),
  last_name: string().required("Last name cannot be empty."),
  phone_number: string(),
  email: string().email("Email must be a valid email."),
  line_id: string().nullable(),
  whatsApp: string().nullable(),
  weChat: string().nullable(),
  instagram: string().nullable(),
  facebook: string().nullable(),
  date_of_birth: date(),
  gender: string(),
  age: string(),
  nationality: string().nullable(),
  ethnicity: string().nullable(),
  country_of_residence: string().nullable(),
  spoken_language: string().nullable(),
  passport_no: string().nullable(),
  id_card: string().nullable(),
  tax_id: string().nullable(),
  occupation: string().nullable(),
  education: string().nullable(),
  address: string().nullable(),
  city: string().nullable(),
  zip_code: string().nullable(),
  country: string().nullable(),
  talent: string().nullable(),
  medical_background: string().nullable(),
  tattoo_scar: string().nullable(),
  underware_shooting: boolean(),
  in_town: boolean(),
  emergency_contact_name: string(),
  emergency_contact_relationship: string().nullable(),
  emergency_contact_details: string(),
  [1]: mixed(),
  [2]: mixed(),
  [3]: mixed(),
  [4]: mixed(),
  [5]: mixed(),
  [6]: mixed(),
  approved: boolean(),
  Measurement: MeasurementSchema,
  Experiences: array(ExperienceSchema),
});
export const updateModelSchema = object({
  model_id: string().nullable(),
  first_name: string().required("First name cannot be empty."),
  last_name: string().required("Last name cannot be empty."),
  phone_number: string().nullable(),
  email: string().email("Email must be a valid email.").nullable(),
  line_id: string().nullable(),
  whatsApp: string().nullable(),
  weChat: string().nullable(),
  instagram: string().nullable(),
  facebook: string().nullable(),
  date_of_birth: date().nullable(),
  gender: string().nullable(),
  age: string().nullable(),
  nationality: string().nullable(),
  ethnicity: string().nullable(),
  country_of_residence: string().nullable(),
  spoken_language: string().nullable(),
  passport_no: string().nullable(),
  id_card: string().nullable(),
  tax_id: string().nullable(),
  occupation: string().nullable(),
  education: string().nullable(),
  address: string().nullable(),
  city: string().nullable(),
  zip_code: string().nullable(),
  country: string().nullable(),
  talent: string().nullable(),
  medical_background: string().nullable(),
  tattoo_scar: string().nullable(),
  underware_shooting: boolean().nullable(),
  in_town: boolean().nullable(),
  emergency_contact_name: string().nullable(),
  emergency_contact_relationship: string().nullable(),
  emergency_contact_details: string().nullable(),
  [1]: mixed(),
  [2]: mixed(),
  [3]: mixed(),
  [4]: mixed(),
  [5]: mixed(),
  [6]: mixed(),
  approved: boolean().required('Please specify whether or not the model is approved.'),
  Measurement: MeasurementSchema,
  Experiences: array(ExperienceSchema),
});

export  function validateUpdateModelForm(data) {
  return updateModelSchema
    .validate(data)
    
}


export  function validateCreateModelForm(data) {
  return createModelSchema
    .validate(data)
    
}
